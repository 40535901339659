import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {CopyBlock, vs2015} from 'react-code-blocks';
import {Link} from 'gatsby';
import {LiteYouTubeEmbed} from 'react-lite-youtube-embed';

import PageLayout from '../../components/PageLayout';
import SEO from '../../components/seo';
import '../../App.css';
require('typeface-rubik');

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavButton = styled.button`
    background-color: white;
    border-width: 6px;
    color: #000000;
    font-size: 25px;
`;

const NavButtonSelected = styled.button`
    border-width: 6px;
    color: #000000;
    font-size: 25px;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const heading = {
    color: 'rgb(0, 208, 126)',
    cursor: 'pointer'
};

const IndexPage = () => {
    const [screenWidth, setScreenWidth] = useState('70%');
    const [windowWidth, setWindowWidth] = useState(0);
    const [username, setUsername] = useState('harshvardhhan');
    const forked = `${'https://github.com/'}${username}${'/webkit'}`;
    const edited = `${'https://github.com/'}${username}${'/webkit/edit/master/src/config.js'}`;
    const config = `${'https://github.com/'}${username}${'/webkit/edit/master/gatsby-config.js'}`;
    const file = `${'https://github.com/'}${username}${'/webkit/new/master/content/blog'}`;
    const blogPath = "`${__dirname}/content/blog`"
    const assetPath = "`${__dirname}/content/assets`"
    const youtubeLink = "`youtube:https://www.youtube.com/embed/y6120QOlsfU`"

    const markdown = `
      ---
      title: Blog Title
      date: "2020-11-30"
      featuredImage: ./preview.jpg
      description: "blog preview description goes here"
      ---
      
      ## Blog Sub Title
      ${youtubeLink}
      
      Blog Content      
    `;

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth < 480) {
            setScreenWidth('100%');
        }
    }, []);

    return (
        <PageLayout>
            <SEO
                title={'Webkit - Ultimate Starter Kit For Non Technical SEO Consultants'}
                description={'Launch your SEO compliant content website in 2 minutes for free'}
                keywords={[
                    'SEO Analyst',
                    'SEO Technical',
                    'SEO Developer',
                    'SEO Analytics'
                ]}
                image={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Ftoolkit.jpg?alt=media&token=e3b4a777-d719-4a8e-87d1-1dd955fa2978'}
            />
            <Container style={{backgroundColor: '#2ecc7112'}}>
                <Banner windowWidth={windowWidth}/>
            </Container>

            <div className='wrapper'>
                <section className='columns'>
                    <div className='column'>
                        <Link to={'/webkit/launch'}>
                            <NavButton>
                                {'Launch'}
                            </NavButton>
                        </Link>
                    </div>
                    <div className='column'>
                        <Link to={'/webkit/modify'}>
                            <NavButton>
                                {'Modify'}
                            </NavButton>
                        </Link>
                    </div>
                    <div className='column'>
                        <NavButtonSelected>
                            {'Create'}
                        </NavButtonSelected>
                    </div>
                </section>
            </div>

            <Container>
                <h1>Create your blog 📃</h1>
            </Container>

            <Container style={{backgroundColor: '#2ecc7112', margin: '0 auto', width: screenWidth}}>
                <span style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>Please ensure your github username is present here -</span>

                <div className='form-group'>
                    <span style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>Github Username:</span>
                    &nbsp;
                    <input
                        style={{
                            fontWeight: '600',
                            fontSize: '35px',
                            textAlign: 'center',
                            lineHeight: '1.3',
                            width: screenWidth
                        }}
                        className='form-field'
                        type='text'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder='domain.tld'
                    />
                </div>
            </Container>

            <div style={{margin: '0 auto', width: screenWidth}}>
                <h2>
                    1. Create a new blog by entering a new filename
                    <a
                        style={heading}
                        onClick={() => window.open(file)}
                    >
                        {' '}{'here'}{' '}
                    </a> and create a index.md in it. Your file name decides your URL.
                </h2>
                <LiteYouTubeEmbed
                    id='rCxrhiHzpZQ'
                    title="Create a blog"
                />
                <br/>
                <h2>This will make your blog URL like this - </h2>
                <img
                    style={{
                        width: '100%',
                        borderImageWidth: '2px',
                        borderColor: '#00000'
                    }}
                    src={require('../../images/url.svg')}
                />
            </div>


            <div style={{margin: '0 auto', width: screenWidth}}>
                <h2>
                    2. Copy the following template in your index.md and start writing your blog.
                    Every time you want to save, click on <a style={heading}>Commit changes</a>
                </h2>
                <CopyBlock
                    language={'markdown'}
                    text={markdown}
                    showLineNumbers={true}
                    theme={vs2015}
                    wrapLines={true}
                    codeBlock={true}
                    width='100%'
                />
                <h2>
                    Do ensure there is a <a style={heading} onClick={() => window.open('https://github.com/TrakBit/webkit/blob/master/content/blog/blog-slug/preview.jpg')}>preview.jpg</a> of "1024px X 600px" in order to make every page as a rich social media link.
                </h2>
            </div>

        </PageLayout>
    );
};

const Banner = ({windowWidth}) => {
    if (windowWidth > 480) {
        return (
            <>
                <h1 style={{textAlign: 'center', fontSize: '5vw', lineHeight: '1.5', fontWeight: '600'}}>
                    Setting Up and Launching<br/>Webkit Site
                </h1>
            </>
        );
    } else {
        return (
            <>
                <h1 style={{fontSize: '9vw', textAlign: 'center', lineHeight: '1.3'}}>
                    Setting Up and Launching<br/>Webkit Site
                </h1>
            </>
        );
    }
};

export default IndexPage;
